export default class PopupParnter {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;
        this.root = el;
        this.close = this.root.querySelector('[data-popup-close]');
        this.overlay = this.root.querySelector('[data-popup-overlay]');

        return true;
    }

    init() {
        this.bindClose();
        this.bindOverlayClose();
    }

    bindOverlayClose() {
        if (!this.overlay) return;
        this.overlay.addEventListener('click', this.closePopup.bind(this));
    }

    bindClose() {
        if (!this.close) return;
        this.close.addEventListener('click', this.closePopup.bind(this));
    }

    closePopup() {
        this.root.remove();
    }
}
