import PopupParnter from '../modules/popupPartner';
class PartnersSection {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;
        this.root = el;
        this.cards = this.root.querySelectorAll('[data-parnters-card]');

        this.popupInstance = null;

        return true;
    }

    init() {
        this.initCards();
    }

    initCards() {
        if (!this.cards) return;

        this.onCard = this.onCardClick.bind(this);
        this.cards.forEach((item, index) => {
            item.addEventListener('click', event => this.onCard(event, index));
        });
    }

    onCardClick(event, index) {
        this.resetCards();

        this.cards.forEach(item => {
            item.style.pointerEvents = 'none';
        });

        const { currentTarget } = event;
        currentTarget.classList.add('active');

        const data = JSON.parse(
            currentTarget.getAttribute('data-parnters-card')
        );
        this.loadPopup(data);
    }

    loadPopup(data) {
        const formData = new FormData();
        formData.append('action', 'partner_popup');

        Object.entries(data).forEach(([key, value]) => {
            formData.append(key, value);
        });

        $.ajax({
            type: 'POST',
            url: ajax.url,
            enctype: 'multipart/form-data',
            processData: false,
            contentType: false,
            data: formData,
            success: function (response) {
                const data = JSON.parse(response);
                const { html } = data;

                document.body.insertAdjacentHTML('beforeend', html);
                this.setPopup();
            }.bind(this),
            error: function () {}.bind(this),
            complete: function () {
                this.cards.forEach(item => {
                    item.style.pointerEvents = 'all';
                });
            }.bind(this),
        });
    }

    setPopup() {
        const popup = document.querySelector('[data-popup]');
        if (popup) this.popupInstance = new PopupParnter(popup);
    }

    resetCards() {
        if (!this.cards) return;
        this.cards.forEach(item => item.classList.remove('active'));
    }
}

const sections = document.querySelectorAll('[data-partners]');
const initPartners = sections => {
    if (!sections) return;

    sections.forEach(el => {
        new PartnersSection(el);
    });
};

initPartners(sections);
